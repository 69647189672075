import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h5>{`Problem #1`}</h5>
    <h6>{`Performance issues with multiple markers`}</h6>
    <p>{`At the time of implementation the library was not jet designed to include a large amount of
`}<strong parentName="p">{`markers`}</strong>{` and track them without creating `}<strong parentName="p">{`performance issues`}</strong>{`.`}</p>
    <p>{`The plan was to track `}<strong parentName="p">{`47 images`}</strong>{` over `}<strong parentName="p">{`8 chapters`}</strong>{` of the magazine but this just didn't work.
So I had to come up with a solution for this problem.
In my research I found out that it is possible to track around `}<strong parentName="p">{`12 markers`}</strong>{` at the same time without
creating performance issues.
The fix then was to split the `}<strong parentName="p">{`images`}</strong>{` into groups and load the `}<strong parentName="p">{`markers`}</strong>{` only for one group at the
same time.`}</p>
    <p>{`Then next question that arrises is how to split the images over the magazine?
There I got pretty lucky with the distribution in the `}<strong parentName="p">{`8 chapters`}</strong>{`.
The chapter with the most images includes `}<strong parentName="p">{`14`}</strong>{` and this was still ok for the performance.
So in the end the website was separated into the `}<strong parentName="p">{`8 chapters`}</strong>{` and for every chapter only the
included images were tracked.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      